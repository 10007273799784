// 展示图片骨架屏最多展示 3*3 张图片，如需支持其他布局，设置 maxColumn 和 pageSize，计算公式：pageSize = maxColumn^2
<template>
  <div class="image-shower">
    <el-container class="image-container">
      <el-main class="container-main">

        <!--在图片加载完成前，使用动画渲染-->
        <el-skeleton class="image-skeleton" :loading="loading" animated :count="1">
          <template slot="template">
            <div class="skeleton-content" v-bind:style="{ 'grid-template-columns': gridLayout, 'grid-auto-rows': imageHeight }">
              <el-skeleton-item v-for="item in pictureList.length" :key="item" class="skeleton-image" :style="{ height: imageHeight }" variant="image" />
            </div>
          </template>
          
          <!--展示图片-->
          <template>
            <div class="image-content" style="display: grid;" v-bind:style="{ 'grid-template-columns': gridLayout, 'grid-auto-rows': imageHeight }">
              <div class="image-item" v-for="(item, index) in pictureList" :key="index">

                <!-- 鼠标滑过图片展示的四角 -->
                <div class="image-corner" v-if="item.photoName === selectedPhotoName && isHover">
                  <div class="image-border image-border1"></div>
                  <div class="image-border image-border2"></div>
                  <div class="image-border image-border3"></div>
                  <div class="image-border image-border4"></div>
                </div>

                <div class="image-header">
                  <!-- 图片类型标签 -->
                  <div class="header-item" style="flex-grow: 1">
                    <el-tag :type="handleImageTagColor(item)" effect="dark" v-if="imageIsShowDetail(item)">{{item.photoStatus}}</el-tag>
                  </div>

                  <!-- 是否展示图片详细信息按钮 -->
                  <div
                    class="header-item"
                    v-bind:class="{ 'show-detail': !imageIsShowDetail(item), 'hide-detail': imageIsShowDetail(item) }"
                    @click="handleImageDetailVisible(item)"
                    :title="showOrHidImageDetailTitle(item)"
                    v-if="item.photoName === selectedPhotoName && isHover"
                  ></div>

                  <!-- 是否手动预览 -->
                  <div class="header-item view-button" @click="handleManualViewImage()" title="手动预览" v-if="item.photoName === selectedPhotoName && isHover">
                  </div>

                  <!-- 下载图片 -->
                  <div class="header-item download-button" @click="handleDownloadImage(item)" title="下载图片" v-if="item.photoName === selectedPhotoName && isHover">
                  </div>
                </div>

                <!-- 图片名字 -->
                <div class="image-footer" v-if="imageIsShowDetail(item)">
                  <span class="footer-item" v-bind:class="{ 'normal-text': imageIsNormal(item), 'abnormal-text': !imageIsNormal(item) }">
                    <div class="footer-text" v-bind:class="{ 'normal-text': imageIsNormal(item), 'abnormal-text': !imageIsNormal(item) }"></div>
                    {{ item.lineName+ "  " +item.photoName }}
                  </span>
                </div>

                <!-- 鼠标移入图片所展示的tooltip -->
                <el-tooltip placement="right-start" :hide-after=15000 >
                  <div slot="content">
                    <h2>图片详情</h2>
                    <ul>
                      <li>线路信息：{{item.lineName}}</li>
                      <li>杆塔信息：{{item.towerName}}</li>
                      <li>图片名称：{{item.photoName}}</li>
                      <li>图片类型：{{item.type}}</li>
                      <li>拍摄位置：{{item.position}}</li>
                      <li>拍摄时间：{{item.shootTime}}</li>
                      <li>检测状态：{{item.photoStatus}}</li>
                      <li>缺陷位置：{{item.isNormal ? "正常" : item.defects}}</li>
                    </ul>
                  </div>

                  <!-- 具体图片 -->
                  <el-image
                    class="image"
                    style="width: 100%; height: 26.4vh; border: solid 1px #FFF;"
                    :style="{ height: imageHeight }"
                    :src="item.imgUrl"
                    fit="fill"
                    v-loading="item.isLoading"
                    element-loading-text="拼命加载中..."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                    @load="handleImageLoadFinish(index)"
                    @error="handleImageLoadFinish(index)"
                    @mouseover="handleHoverImage(index, true)"
                    @mouseleave="handleHoverImage(index, false)"
                  >
                    <div slot="error" class="image-slot" style="width: 100%; background: #FFF">
                      <i class="el-icon-picture-outline" style="position: relative; left: 45%; top: 45%;">加载图片失败...</i>
                    </div>
                  </el-image>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-main>
    </el-container>

    <!-- 手动预览弹窗 -->
    <el-dialog class="my-dialog2 manual-dialog" title="手动预览" :close-on-click-modal="false" :visible.sync="manualPreviewImageDialogVisible" width="95vw" top="5vh">
      <image-manual-shower v-bind:pictureList="previewPictureList" />
    </el-dialog>

    <!-- 自动播放模式下切换手动模式的confirm -->
    <el-dialog class="my-dialog" title="切换手动模式" :close-on-click-modal="false" :visible.sync="switchManualModeDialogVisible" top="25vh">
      <span style="color: #FFF">点击继续会暂停自动播放任务，转为手动预览模式，是否继续？</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="switchManualModeDialogVisible = false">取 消</el-button>
        <el-button class="save-button" @click="switch2ManualMode()">继 续</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImageManualShower from "./imageManualShower.vue";
import { downloadPhoto } from "@/http/displayPhotoApi.js";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import qs from "qs";
import { saveAs } from "file-saver";
export default {
  name: "imageShower",


  components: {
    ImageManualShower
  },


  data() {
    return {
      loading: true,
      isShow: true,
      selectedPhotoName: "",
      isHover: false,                         // 鼠标滑过
      switchManualModeDialogVisible: false,   // 由于全屏时el-element的弹窗显示不了在最顶层，因此这里使用自定义的弹窗实现询问对话框
      manualPreviewImageDialogVisible: false, // 手动预览图片窗口显示状态
      defaultScreenLayoutIndex: 2,            // 默认展示屏数，3*3
      normalLayout: [                         // 根据每屏界面布局设置不同的样式
        { layout: 1, height: "81vh" },
        { layout: 4, height: "40vh" },
        { layout: 9, height: "26vh" },
        { layout: 16, height: "19vh" }
      ],
      fullScreenLayout: [                     // 全屏时布局样式
        { layout: 1, height: "95vh" },
        { layout: 4, height: "47vh" },
        { layout: 9, height: "31vh" },
        { layout: 16, height: "23vh" }
      ]
    };
  },


  props: {
    /*
     * @Date 2022/07/05 09:25:45
     * 当前显示的图片列表
     */
    pictureList: {
      type: Array,
      default: () => [],
      require: true
    },


    /*
     * @Date 2022/07/05 09:59:17
     * 手动预览图片列表
     */
    previewPictureList: {
      type: Array,
      default: () => [],
      require: true
    },

    /*
     * @Date 2022/07/05 09:59:33
     * 界面布局：1->1*1, 4 ->2*2, 9->3*3, 16->4*4
     */
    layout: {
      type: Number,
      default: () => 9,
      require: true
    },

    /*
     * @Date 2022/07/05 09:59:42
     * 是否全屏
     */
    isFullScreen: {
      type: Boolean,
      default: () => false,
      require: false
    },

    /*
     * @Date 2022/07/05 10:00:36
     * 是否显示脚注
     */
    isShowDetail: {
      type: Boolean,
      default: () => false,
      require: false
    },

    /*
     * @Date 2022/07/05 10:00:48
     * 是否正在自动播放
     */
    isPlaying: {
      type: Boolean,
      default: () => false,
      require: false
    }
  },


  computed: {
    /*
     * @Date 2022/07/05 10:02:05
     * 判断图片是否正常图片
     */
    imageIsNormal() {
      return item => {
        return item.isNormal;
      };
    },

    /*
     * @Date 2022/07/05 10:02:12
     * 是否显示图片脚注
     */
    imageIsShowDetail() {
      return item => {
        return item.isShowDetail;
      };
    },

    /*
     * @Date 2022/07/05 10:02:20
     * 显示或隐藏脚注标题
     */
    showOrHidImageDetailTitle() {
      return item => {
        return item.isShowDetail ? "隐藏图片标签和名称" : "显示图片标签和名称";
      };
    },

    /*
     * @Date 2022/07/05 09:18:29
     * 计算每张图片的高度
     */
    imageHeight: function() {
      let layout;

      // 1、全屏下在当前布局下每张照片的高度
      if (this.isFullScreen) {
        layout = this.fullScreenLayout.find(l => {
          return l.layout === this.layout;
        });

      // 2、非全屏下在当前布局下每张照片的高度
      } else {
        layout = this.normalLayout.find(l => {
          return l.layout === this.layout;
        });
      }

      if (layout) {
        return layout.height;
      } else {

        // 3、几乎不会出现的情况，没有layout的情况下默认使用3*3布局的图片高度
        return this.normalLayout[this.defaultScreenLayoutIndex].height;
      }
    },

    /*
     * @Date 2022/07/05 09:11:35
     * 计算界面布局有多少列，并将容器宽度平均分成rowCount份。例：rowCount = 根号9 = 3
     */
    gridLayout: function() {
      let rowCount = Math.round(Math.sqrt(this.layout));
      return "repeat(" + rowCount + ", 1fr)";
    }
  },


  mounted() {
    // 设置 loading 如果为真，会一直不显示图片，需要变成
    this.loading = false;
    // 图片列表的JSON结构
    // this.pictureList = [
    //   {
    //     id: 1,
    //     imgUrl: "https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg",
    //     name: "8",
    //     tag: 1-未审核，2-正常，3-缺陷
    //   }
    // ];
  },


  methods: {
    /**
     * @Author KR0132
     * @Date 2022/04/06 09:21:55
     * @Description 处理图片标签字体颜色
     */
    handleImageTagColor(image) {
      switch(image.photoStatus) {
        case "未检测": 
          return "";
        case "缺陷":
          return "danger";
        case "正常":
          return "success";
        case "误检":
          return "warning";
        default: 
          return "";
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 10:12:01
     * @Description 处理单张图片显示或隐藏脚注
     */
    handleImageDetailVisible(item) {
      let index = this.pictureList.findIndex(i => {
        return i.photoName.indexOf(item.photoName) > -1;
      });
      this.pictureList[index].isShowDetail = !this.pictureList[index].isShowDetail;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 10:17:01
     * @Description 切换手动模式按键
     */
    handleManualViewImage() {
      if (this.isPlaying) {
        this.switchManualModeDialogVisible = true;
      } else {
        this.switch2ManualMode();
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 10:20:25
     * @Description 下载图片
     */
    handleDownloadImage(item) {
      showLoading("下载图片中...");
      setTimeout(() => {
        closeLoading();
      }, 5000);
      let params = { imgUrl: item.imgUrl };
      console.log(params);
      downloadPhoto(params)
        .then(res => {
          let blob = new Blob([res], { type: "application/octet-stream" });
          saveAs(blob, item.photoName);
          closeLoading();
        })
        .catch(() => {
          closeLoading();
        });
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 10:19:28
     * @Description 处理图片加载结果
     */
    handleImageLoadFinish(index) {
      this.pictureList[index].isLoading = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 09:41:21
     * @Description 处理鼠标滑过图片,初始页面没有滑过图片或滑到新的图片
     */
    handleHoverImage(index, isHover) {
      let photo = this.pictureList[index];

      if (this.selectedPhotoName == "" || photo.photoName.indexOf(this.selectedPhotoName) == -1) {
        this.selectedPhotoName = photo.photoName;
        this.isHover = isHover;
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/07/05 10:27:03
     * @Description 切换到手动模式
     */
    switch2ManualMode() {
      if (this.isPlaying) {
        this.$emit("playOrPause", false);
      }

      /*
       * @Date 2022/02/23 14:27:49
       * 由于全屏时无法预览图片，手动预览时先退出全屏
       */
      this.$emit("exitFullScreen");
      this.manualPreviewImageDialogVisible = true;
      this.switchManualModeDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.normal-text {
  background: white;
  color: white;
}
.abnormal-text {
  background: red;
  color: red;
}
</style>
