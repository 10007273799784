/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-04-12 11:33:12
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-09-27 11:24:25
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\displayPhotoApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 播放图片界面接口
import axios from "./ajax.js"; // 导入ajax

/*
 * @Date 2022/04/02 14:47:11
 * 刷新界面初始化播放界面的图片
 */
export const initDisplayPhotos = params => {
  return axios({
    url: "/eprdms/display/init",
    method: "post",
    data: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:14:18
 * @Description 查询轮播图片
 */
export const getDisplayPhotos = params => {
  return axios({
    url: "/eprdms/display/get",
    method: "post",
    data: params
  });
};

/**
 * @Author KR0288
 * @Date 2022/05/18 10:14:57
 * @Description 下载图片
 */
export const downloadPhoto = params => {
  return axios({
    url: "/eprdms/display/download",
    method: "post",
    params,
  });
};

/*
 * @Date 2022/02/22 17:13:12
 * 查询包含巡检图片的日期
 */
export const getContainPhotoDates = params => {
  return axios({
    url: "/eprdms/display/get/dates",
    method: "post",
    data: params
  });
};
