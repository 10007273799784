<template>
  <div class="image-manual-shower">
    <div class="image-list">
      <div class="son-header">
        <div class="elbum-icon" />
        <h4 class="aside-title">图片列表</h4>
      </div>

      <!-- 图片列表 -->
      <div class="list">
        <div :class="currentIndex == index ? 'item-actived' : ''" :style="{color: picture.isNormal ? '#FFF' : '#F00'}" class="list-item" v-for="(picture, index) in pictureList" :key="index" @click="handleClick(index)">
          <i class="el-icon-picture" style="margin-right:1vh"></i>
          {{ picture.photoName }}
        </div>
      </div>
      <div style="color: #00ffbf; font-size: 1.2em; text-align: center">共 {{ pictureList.length }} 张，当前第 {{ currentIndex + 1 }} 张</div>
    </div>

    <!-- 图片 -->
    <div class="image">
      <el-carousel ref="imageCarouselRef" indicator-position="none" :autoplay="false" style="height: 100%;" @change="handleImageChange">
        <el-carousel-item v-for="(item, index) in pictureList" :key="index">
          <el-image class="image" style="width: 58vw; height: 77.8vh; border: solid 1px #FFF; " :src="item.imgUrl" fit="fill" :preview-src-list="previewPictures"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 图片详情 -->
    <div class="image-desc">
      <div class="son-header">
        <div class="photo-detail-icon" />
        <h4 class="aside-title">图片详情</h4>
      </div>
      <el-descriptions :column="1" border>
        <el-descriptions-item>
          <template slot="label">
            图片名
          </template>
          {{ currentPicture.photoName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            类型
          </template>
          {{ currentPicture.type }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            杆塔信息
          </template>
          {{ currentPicture.towerName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            拍摄位置
          </template>
          {{ currentPicture.position }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            拍摄时间
          </template>
          {{ currentPicture.shootTime }}
        </el-descriptions-item>
          <el-descriptions-item>
          <template slot="label">
            检测状态
          </template>
          {{ currentPicture.photoStatus }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            缺陷信息
          </template>
          {{ currentPicture.isNormal ? "正常" : currentPicture.defects }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: "imageManualShower",


  data() {
    return {
      isClickList: false,
      currentIndex: 0
    };
  },


  props: {
    /*
     * @Date 2022/07/05 10:39:06
     * 所有图片组成的列表
     */
    pictureList: {
      type: Array,
      default: () => [],
      require: true
    }
  },


  computed: {
    /*
     * @Date 2022/07/05 10:40:03
     * 图片总数
     */
    pictureCount: function() {
      return this.pictureList.length;
    },

    /*
     * @Date 2022/07/05 10:40:09
     * 当前选中的图片
     */
    currentPicture: function() {
      return this.pictureList[this.currentIndex];
    },

    /*
     * @Date 2022/07/05 10:40:29
     * 预览图片
     */
    previewPictures: function() {
      let list = [];
      list.push(this.pictureList[this.currentIndex].imgUrl);
      return list;
    }
  },


  methods: {
    /**
     * @Author KR0132
     * @Date 2022/02/19 11:34:08
     * @Description 处理单击图片列表
     */
    handleClick(index) {
      this.isClickList = true;
      this.currentIndex = index;
      this.$refs["imageCarouselRef"].setActiveItem(index);
      this.isClickList = false;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 11:34:29
     * @Description 处理图片被切换事件
     */
    handleImageChange(index) {
      if (!this.isClickList) {
        this.currentIndex = index;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
