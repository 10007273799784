<template>
  <div class="photo-display">
    <RouterMenu />

    <!-- 没有选择任何轮播图的情况 -->
    <el-empty class="empty-content" v-if="displayPictureList.length == 0" description="请先在播放设置中添加图片到播放列表！">
      <el-button class="config-button" @click="autoPalySettingDialogVisible = true">立即配置</el-button>
    </el-empty>

    <!-- 轮播图 -->
    <div class="iamge-content" v-else id="imageshower">
      <image-shower
        class="picture-show"
        ref="imageshower"
        :pictureList="currentDisplayImages"
        :previewPictureList="displayPictureList"
        :layout="imageShowerSetting.layout"
        :isFullScreen="isFullScreen"
        :isShowDetail="imageShowerSetting.isShowDetail"
        :isPlaying="isPlaying"
        @playOrPause="playOrPauseAutoPlay"
        @exitFullScreen="exitFullScreen"
      />
      <div class="switch-progress" v-if="isFullScreen && isPlaying" style="position: relative; left: 2vh; bottom: 1vh">
        <span v-if="isPlaying">{{ currentPage }}</span>
        <el-progress type="line" :percentage="switchProgress" :format="switchProgressText" color="#00ffbf" v-if="isPlaying && isAboveLayout"></el-progress>
        <span v-if="!isAboveLayout && isPlaying" class="onPlay">正在播放</span>
      </div>
    </div>

    <!-- 底部进度条和按钮组 -->
    <div class="footer">

      <div class="switch-progress">
        <span v-if="isPlaying">{{ currentPage }}</span>
        <el-progress v-if="isPlaying && isAboveLayout" type="line" :percentage="switchProgress" :format="switchProgressText" color="#00ffbf"></el-progress>
        <span v-if="!isAboveLayout && isPlaying" class="onPlay">正在播放</span>
      </div>

      <div>
        <el-button class="kr-normal-button el-icon-full-screen" title="全屏播放" @click="fullScreen()">全屏</el-button>
        <el-button class="kr-normal-button" :title="isPlaying ? '暂停' : '播放'" :class="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'" @click="playOrPauseAutoPlay(!isPlaying)" v-if="$hasElement('display-btn-init')">
          {{ isPlaying ? "暂停" : "播放" }}
        </el-button>
        <el-button class="kr-normal-button el-icon-setting" title="设置播放模式" @click="handleAutoPlaySetting()">播放设置</el-button>
        <el-button class="kr-normal-button el-icon-notebook-2" title="查看播放目录" @click="handleCheckContent()">查看目录</el-button>
      </div>

    </div>

    <!-- 播放设置弹窗 -->
    <el-dialog class="my-dialog display-setting-dialog" title="播放设置" :close-on-click-modal="false" :visible.sync="autoPalySettingDialogVisible" @closed="closePlaySetting" width="30vw" top="25vh">
      <el-form ref="autoPlayFormRef" :model="autoPalySettingForm" label-width="120px"  prop="type">
        <el-form-item label="播放图片">
          <el-input v-model="autoPalySettingForm.pictures">
            <el-button slot="append" icon="el-icon-refresh" style="width: 6vw" @click="showReloadPictureDialog" v-if="$hasElement('display-btn-get')">重新加载</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="播放间隔">
          <el-input placeholder="请输入播放间隔(只能输入整数)" v-model="autoPalySettingForm.switchInterval" onkeyup="value=value.replace(/[^\d]/g,'')" :maxlength="3" class="input-with-select">
            <el-select v-model="autoPalySwitchIntervalUnit" slot="append" placeholder="请选择时间单位" style="width: 6vw">
              <el-option label="毫秒" :value="1"></el-option>
              <el-option label="秒" :value="1000"></el-option>
              <el-option label="分" :value="60000"></el-option>
              <el-option label="时" :value="86400000"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="界面布局">
          <el-select v-model="autoPalySettingForm.layout" placeholder="请选择界面布局">
            <el-option label="1*1" :value="1"></el-option>
            <el-option label="2*2" :value="4"></el-option>
            <el-option label="3*3" :value="9"></el-option>
            <el-option label="4*4" :value="16"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否循环播放">
          <el-select v-model="autoPalySettingForm.isCirclePlay" placeholder="请选择是否开启循环播放">
            <el-option label="开启" :value="true"></el-option>
            <el-option label="不开启" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否显示图片信息">
          <el-select v-model="autoPalySettingForm.isShowDetail" placeholder="请选择是否显示图片信息">
            <el-option label="显示" :value="true"></el-option>
            <el-option label="隐藏" :value="false"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-button" @click="closePlaySetting">取 消</el-button>
        <el-button class="save-button" @click="updateAutoPlayingSetting">更 新 设 置</el-button>
      </span>
    </el-dialog>

    <!-- 加载图片对话框 -->
    <el-dialog class="my-dialog2 load-picture-dialog" title="加载播放图片" :close-on-click-modal="false" :visible.sync="reloadPictureListDialogVisible" width="80vw" top="10vh">
      <el-form class="load-picture-form" ref="reloadPictureFormRef" :inline="true" :model="reloadPictureForm" :rules="reloadPictureFormRules" label-width="6em">
        <el-form-item label="巡检线路" prop="lineId">
          <el-select :multiple="true" v-model="reloadPictureForm.lineIds" placeholder="请选择巡检线路" collapse-tags clearable @visible-change="handleLineChange" @clear="handleClearLine">
            <el-option v-for="(line, index) in lineList" :key="index" :label="line.lineName" :value="line.lineId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="杆塔" prop="towerIds">
          <el-select :multiple="true" v-model="reloadPictureForm.towerIds" collapse-tags clearable placeholder="请选择杆塔">
            <el-option v-for="(tower, index) in towerList" :key="index" :label="tower.towerName" :value="tower.towerId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="起始日期" prop="beginDate">
          <mark-date-picker
            ref="endDateSelector"
            v-model="reloadPictureForm.beginDate"
            v-bind:has-photo-dates="hasPhotoDates"
            :defaultDate="reloadPictureForm.beginDate"
            holder-text="起始日期"
            tag="beginDate"
            style="width: 100%;"
            @date-btn-click="datePickerBtnClick"
            @change-date="changeDate"
          ></mark-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" prop="endDate">
          <mark-date-picker
            ref="endDateSelector"
            v-model="reloadPictureForm.endDate"
            v-bind:has-photo-dates="hasPhotoDates"
            :defaultDate="reloadPictureForm.endDate"
            holder-text="起始日期"
            tag="endDate"
            style="width: 100%;"
            @date-btn-click="datePickerBtnClick"
            @change-date="changeDate"
          ></mark-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="kr-normal-button" @click="handleGetPictures" title="查询图片" v-if="$hasElement('display-btn-get')">查 询</el-button>
          <el-button class="kr-normal-button" @click="handleReloadPictures" title="加载所有已选中图片" v-if="$hasElement('display-btn-get')">重 新 加 载</el-button>
        </el-form-item>
      </el-form>
      <div class="picture-body">
        <div class="picture-list">
          <div class="picture-check-box">
            <el-checkbox :checked="true" @change="handleCheckedAllBox">全选</el-checkbox>            
          </div>
          <div class="picture-check-box" v-for="(picture, index) in prePictureList" :key="index">
            <el-checkbox v-model="picture.isCheck" @change="handleClickPhoto(index, $event)" class="picture-checkbox">{{ picture.photoName }}</el-checkbox>
            <img :src="picture.imgUrl" class="picture-item">
          </div>
        </div>
        <div style="text-align: center; color: #FFF">
         共 {{prePictureList.length}} 张，已选中 {{selectedPictures.length}} 张
        </div>
      </div>
    </el-dialog>

    <!-- 查看目录抽屉 -->
    <el-drawer class="content-drawer" title="播放列表" :visible.sync="displayContentDrawerVisible" direction="rtl" size="20%">
      <div class="content-list">
        <el-dropdown trigger="click" placement="bottom" v-for="(picture, index) in selectedPictures" :key="picture.photoName" @command="handleClickMenu(index, $event)">
          <div :class="checkPictureIsActive(index) ? 'item-actived' : ''">
            <i class="el-icon-picture"></i>
            {{ index + 1 + "、" + picture.photoName }}
          </div>
          <el-dropdown-menu slot="dropdown" style="left:85vw">
            <el-dropdown-item icon="el-icon-video-play" command="beginHere">从这里开始播放</el-dropdown-item>
            <el-dropdown-item icon="el-icon-top" command="moveToFront">上移</el-dropdown-item>
            <el-dropdown-item icon="el-icon-bottom" command="moveToNext">下移</el-dropdown-item>
            <el-dropdown-item icon="el-icon-delete" command="remove">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div style="color: #00ffbf; font-size: 1.2em; text-align: center">共 {{ displayPictureList.length }} 张，播放进度 {{ displayProgress }}</div>
    </el-drawer>
  </div>
</template>
<script>
import RouterMenu from "@/components/RouterMenu.vue";
import imageShower from "../../components/photoDisplay/imageShower.vue";
import { getDisplayPhotos , getContainPhotoDates, initDisplayPhotos} from "@/http/displayPhotoApi.js";
import { getMultiLineTowerNameList} from "@/http/towerListDetailsApi.js";
import { getLineNames } from "@/http/homeApi.js";
import { showLoading, closeLoading } from "@/utils/js/Loading.js";
import MarkDatePicker from "../../components/photoDisplay/markDatePicker.vue";
import moment from "moment";
import { mapState } from "vuex";
import { md5Encryption2 } from "@/utils/js/md5.js"
export default {
  name: "photoDisplay",
  components: {
    RouterMenu,
    imageShower,
    MarkDatePicker
  },
  data() {
    return {
      /*
       * @Date 2022/06/07 09:06:52
       * 图片信息
       */
      prePictureList: [],     // 查询后台返回的图片数组
      displayPictureList: [], // 手动预览轮播图列表
      imageShowerSetting: {   // 轮播图布局设置
        layout: 9,            // 界面布局
        isShowDetail: true    // 是否显示图片信息
      },

      /*
       * @Date 2022/06/07 09:23:47
       * 全屏播放信息 
       */
      isFullScreen: false, // 是否全屏播放中


      /*
       * @Date 2022/06/07 09:15:25
       * 自动播放设置信息
       */
      autoPalySettingDialogVisible: false, // 是否显示播放设置窗口
      isPlaying: false,                    // 是否正在播放
      autoPalySettingForm: {               
        pictures: "",                      // 图片列表
        switchInterval: 15,                // 自动播放切换间隔，单位ms
        layout: 9,                         // 界面布局：1->1*1, 4 ->2*2, 9->3*3, 16->4*4
        isCirclePlay: true,                // 是否循环播放
        isShowDetail: true                 // 是否显示图片信息
      },               
      switchTimer: -1,                     // 定时任务ID
      percentTimer: -1,                    // 计数ID
      autoPlaySwitchInterval: 5000,        // 自动播放切换事件间隔
      autoPalySwitchIntervalUnit: 1000,    // 自动播放切换事件单位，默认秒
      pageNumber: 1,                       // 页码
      beginIndex: 0,                       // 设置起始播放序号
      imageShowTimeCounter: 0,             // 进度条图片当前显示的时间计数，单位 ms


      /*
       * @Date 2022/06/07 09:32:39
       * 重新加载图片对话框信息
       */
      reloadPictureListDialogVisible: false, // 是否显示加载图片对话框
      isCheck: true,                         // 是否选中图片
      currentPicture: "",                    // 当前选中的图片
      lineList: [],                          // 线路集合
      towerList: [],                         // 杆塔集合
      hasPhotoDates: [],                     // 包含图片的日期
      reloadPictureForm: {                   // 加载图片对话窗口
        lineIds: [],
        towerIds: [],
        beginDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD")
      },
      reloadPictureFormRules: {             // 加载图片表单校验规则
        lineIds: [{ required: true, message: "请选择巡检线路", trigger: "blur" }],
        beginDate: [{ required: true, message: "请选择起始日期", trigger: "blur" }],
        endDate: [{ required: true, message: "请选择结束", trigger: "blur" }]
      },


      /*
       * @Date 2022/06/07 09:42:18
       * 查看目录信息
       */
      displayContentDrawerVisible: false, // 查看目录抽屉是否显示
      pictureTreeProps: {                 // 图片树列表树字段映射
        label: "photoName"
      },
      preMenu: "/photo-display",          // 上一次的菜单，用于判断是否离开页面
      currentDisplayIndex: [],            // 当前展示图片的集合的最大最小下标
    };
  },

  computed: {
    ...mapState(["currentMenu"]),
    
    /*
     * @Date 2022/06/07 10:05:35
     * 当前显示的图片
     */
    currentDisplayImages: function() {
      let begin = (this.pageNumber - 1) * this.imageShowerSetting.layout + this.beginIndex;
      let size = this.displayPictureList.length;
      let step = size - begin > this.imageShowerSetting.layout ? this.imageShowerSetting.layout : size - begin;
      let end = begin + step;

      // 结束一个循环后，重新头开始播放
      if (end >= size) {
        this.beginIndex = 0;
      }
      // console.log('begin: ' + begin + "  " + "size: " + size + "  " + "step: " + step + "  " + "end: " + end);
      this.currentDisplayIndex = [begin, end];
      return this.displayPictureList.filter((p, index) => {
        return index >= begin && index < end;
      });
    },


    /*
     * @Date 2022/02/21 20:24:12
     * 切换进度条
     */
    switchProgress: function() {
      return Math.min(Math.abs((this.imageShowTimeCounter / this.autoPlaySwitchInterval) * 100), 100);
    },


    /*
     * @Date 2022/02/22 10:53:00
     * 播放进度
     */
    displayProgress: function() {
      if (this.currentDisplayIndex.length == 0) {
        return 0;
      }

      let num = parseFloat((this.currentDisplayIndex[1] / this.displayPictureList.length) * 100);
      return num.toFixed(0) + "%";
    },


    /*
     * @Date 2022/02/23 11:09:41
     * 进度条的播放进度
     */
    currentPage: function() {
      let totalPage = Math.ceil(this.displayPictureList.length / this.imageShowerSetting.layout);
      return "播放进度：" + this.pageNumber + "/" + totalPage;
    },


    /*
     * @Date 2022/04/02 11:28:27
     * 选中的巡检图片数量
     */
    selectedPictures: {
      get() {
        if(!this.prePictureList || this.prePictureList.length==0) {
          return [];
        }
        return this.prePictureList.filter(p=>{return p.isCheck;}); 
      },
    },

    /*
     * @Date 2022/06/07 10:42:47
     * 照片数量是否大于layout
     */
    isAboveLayout: function () {
      return this.displayPictureList.length > this.imageShowerSetting.layout
    }
  },

  methods: {
     /**
     * @Author KR0132
     * @Date 2022/04/02 14:48:30
     * @Description 初始化查询
     */
    init() {
      showLoading("初始化参数中....");
      initDisplayPhotos().then(async res => {
         if (res.code === "000000") {
          if (res.data.length == 0) {
            this.prePictureList = [];
          } else {
            console.log(res.data);
            this.prePictureList = await Promise.all(res.data.map(async d => {
              d.isCheck = true;
              d.isShowDetail = true;
              d.isLoading = true;
              d.imgUrl = await md5Encryption2(d.imgUrl);
              return d;
            }));
          }

          if(this.prePictureList.length > 0) {
            this.displayPictureList = JSON.parse(JSON.stringify(this.prePictureList));
            this.autoPalySettingForm.pictures = "已加载" + this.displayPictureList.length + "张图片";
          }
        }
      }).catch(err => {
        this.$message({ message: "初始化播放界面失败", type: "warning" });
      }).finally(() => {
        closeLoading();
      })
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 13:54:13
     * @Description 开启全屏播放
     */
    fullScreen() {
      let div = document.querySelector("#imageshower");
      if (!div) {
        this.$alert("请先在播放配置添加图片到播放列表!", "提示", {
          confirmButtonText: "确定",
          callback: action => {
            this.$message({
              type: "info",
              message: "取消全屏显示"
            });
          }
        });
        return;
      }
      if (div.requestFullscreen) {
        div.requestFullscreen();
      } else if (div.webkitRequestFullScreen) {
        div.webkitRequestFullScreen();
      } else if (div.mozRequestFullScreen) {
        div.mozRequestFullScreen();
      } else if (div.msRequestFullScreen) {
        div.msRequestFullScreen();
      }

      this.isFullScreen = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/23 14:28:33
     * @Description 退出全屏
     */
    exitFullScreen() {
      if(this.isFullScreen) {
        document.exitFullscreen ? document.exitFullscreen() : document.mozCancelFullScreen ? document.mozCancelFullScreen() : document.webkitExitFullscreen ? document.webkitExitFullscreen() : "";
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 13:51:17
     * @Description 开启或停止自动播放
     */
    playOrPauseAutoPlay(start) {
      // 1、播放
      if (start) {
        this.imageShowTimeCounter = 0;

        // 2、判断是否有图片可供播放
        if (this.displayPictureList.length == 0) {
          this.$alert("选中播放图片列表为空, 请先选择播放图片!", "提示", {
            confirmButtonText: "确定",
            callback: action => {
              this.$message({
                type: "info",
                message: "取消播放图片"
              });
            }
          });
          return;
        }

        // 3、图片可以一次性展示完，不需要开启定时器
        if (this.displayPictureList.length > this.imageShowerSetting.layout) {

          // 4、轮播图片定时器
          this.switchTimer = setInterval(() => this.autoPlayTask(), this.autoPlaySwitchInterval);

          // 5、展示进度计数器
          this.percentTimer = setInterval(() => {
            if (this.isPlaying) {
              this.imageShowTimeCounter += 1000;
            } else {
              this.imageShowTimeCounter = 0;
            }
          }, 1000);
        }
        this.isPlaying = true;

      } else {
        // 6、暂停自动播放定时器
        window.clearInterval(this.switchTimer);
        window.clearInterval(this.percentTimer);
        this.imageShowTimeCounter = 0;
        this.switchTimer = -1;
        this.percentTimer = -1;
        this.isPlaying = false;
      }

      this.$message({ message: (start ? "开启" : "暂停") + "自动播放图片任务！", type: "success" });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/23 17:14:02
     * @Description 自动播放任务
     */
    autoPlayTask() {

      // 1、每一页播放前都需要设置进度为0
      this.imageShowTimeCounter = 0;

      // 2、向上取整页数
      let maxPageNumber = Math.ceil(this.displayPictureList.length / this.imageShowerSetting.layout);

      // 3、是否循环播放，当播放到最后一页之后从头开始
      if (this.autoPalySettingForm.isCirclePlay) {
        this.pageNumber = maxPageNumber >= this.pageNumber + 1 ? this.pageNumber + 1 : 1;

      // 4、如果没有设置循环，播放到最后一页后confirm是否需要重新播放
      } else {
        if (this.pageNumber + 1 > maxPageNumber) {
          this.playOrPauseAutoPlay(false);
          this.$confirm("所有图片已播放完成, 是否重头开始自动播放?", "提示", {
            confirmButtonText: "重新播放",
            cancelButtonText: "暂停播放",
            type: "warning"
          }).then(() => {
            this.pageNumber = 1;
            this.playOrPauseAutoPlay(true);

            // 由于进行了下一轮的播放，为了增加用户体验，询问用户是否开启循环播放
            this.$confirm("已重新开始播放图片，当前播放模式可配置为自动循环，是否开启?", "提示", {
              confirmButtonText: "开启",
              cancelButtonText: "不开启",
              type: "warning"
            }).then(() => {
              this.$message({
                type: "success",
                message: "开启自动循环播放成功!"
              });
              this.autoPalySettingForm.isCirclePlay = true;
            }).catch(() => {
              this.pageNumber = 1;
              this.$message({
                type: "info",
                message: "已取消自动循环播放"
              });
            });
          }).catch(() => {
            this.pageNumber = 1;
            this.$message({
              type: "info",
              message: "已取消播放"
            });
          });
        } else {
          this.pageNumber++;
        }
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 09:52:49
     * @Description 进度条显示读秒文字
     */
    switchProgressText() {
      let countdownTime = (this.autoPlaySwitchInterval - this.imageShowTimeCounter) / 1000;
      return countdownTime + "秒后切换到下一屏";
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 13:53:08
     * @Description 打开自动播放设置对话框
     */
    handleAutoPlaySetting() {
      this.autoPalySettingDialogVisible = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 16:42:35
     * @Description 打开重载巡检图片弹窗
     */
    showReloadPictureDialog() {
      this.reloadPictureListDialogVisible = true;
      this.currentPicture = "";
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 17:18:38
     * @Description 线路变更时查询杆塔
     */
    handleLineChange(isVisible) {
      if(!isVisible) {
        if(!this.reloadPictureForm.lineIds || this.reloadPictureForm.length == 0) {
          this.$message.warning('请先选择线路！');
          return;
        }
        let params = { lineIds: (this.reloadPictureForm.lineIds) };
        getMultiLineTowerNameList(params).then(res => {
          if (res.code === "000000") {
            this.towerList = res.data;
          }
        });
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/04/02 10:00:37
     * @Description 清空线路下拉框
     */
    handleClearLine() {
      this.reloadPictureForm.towerIds = [];
      this.towerList = [];
    },

    /**
     * @Author KR0132
     * @Date 2022/02/23 10:54:21
     * @Description 日历事件
     */
    datePickerBtnClick(date) {
      if (!this.reloadPictureForm.lineIds || this.reloadPictureForm.lineIds.length==0) {
        this.$message.warning("请先选择线路！");
        return;
      }
      let params = {
        lineIds: this.reloadPictureForm.lineIds,
        towerIds: this.reloadPictureForm.towerIds,
        dayOfMonth: date
      };

      getContainPhotoDates(params).then(res => {
        this.hasPhotoDates = res.data;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/23 10:54:43
     * @Description 处理日期选择的更改
     */
    changeDate(params) {
      if (params.tag === "beginDate") {
        this.reloadPictureForm.beginDate = params.date;
      } else {
        this.reloadPictureForm.endDate = params.date;
      }

      // console.log('date: ' + params.date + "  " + "tag: " + params.tag);
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 16:02:33
     * @Description 处理全选图片
     */
    handleCheckedAllBox(isChecked) {
      this.prePictureList.forEach(p => {
        p.isCheck = isChecked;
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/23 09:14:44
     * @Description 处理单击图片
     */
    handleClickPhoto(index, select) {
      this.currentPicture = this.prePictureList[index];
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 17:31:25
     * @Description 向后台查询图片
     */
    handleGetPictures() {
      this.currentPicture = "";
      this.$refs["reloadPictureFormRef"].validate(valid => {
        if (valid) {
          showLoading("查询图片中...");
          getDisplayPhotos(this.reloadPictureForm).then(async res => {
          
            if (res.code === "000000") {
              if (res.data.length == 0) {
                this.prePictureList = [];
                this.$alert("查询不到匹配的图片", "提示", {
                  confirmButtonText: "确定"
                });
              } else {
                this.prePictureList = await Promise.all(res.data.map( async d => {
                  d.isCheck = true;
                  d.isShowDetail = true;
                  d.isLoading = true;
                  d.imgUrl = await md5Encryption2(d.imgUrl)
                  return d;
                }));
              }
            } else {
              this.$message({ message: "查询图片失败！", type: "warning" });
            }
          }).catch(err => {
            this.$message({ message: err.data, type: "warning" });
          }).finally(() => {
            closeLoading();
          });
        }
      });
    },

    /**
     * @Author KR0132
     * @Date 2022/02/19 14:59:34
     * @Description 重新加载图片
     */
    handleReloadPictures() {
      let tmp = this.prePictureList.filter(p => {
        return p.isCheck;
      });

      this.$confirm("已选中" + tmp.length + "张图片到播放列表，是否确认完成添加", "确认", {
        confirmButtonText: "完成",
        cancelButtonText: "重新选择",
        type: "success"
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "添加成功!"
          });
          this.displayPictureList = tmp;
          this.autoPalySettingForm.pictures = "已加载" + this.displayPictureList.length + "张图片";
          this.reloadPictureListDialogVisible = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消加载图片"
          });
        });
    },


    /**
     * @Author KR0132
     * @Date 2022/02/19 14:19:56
     * @Description 更新自动播放设置主函数
     */
    updateAutoPlayingSetting() {
      if(this.displayPictureList && this.displayPictureList.length > 0) {
        this.playOrPauseAutoPlay(false);
      }
      this.saveImageShowerSetting();
      this.autoPalySettingDialogVisible = false;
    },

    /**
     * @Author KR0288
     * @Date 2022/06/07 10:18:43
     * @Description 更新自动播放设置功能函数
     */
    saveImageShowerSetting() {
      // 2、更新播放组件配置
      this.imageShowerSetting.layout = this.autoPalySettingForm.layout;
      this.imageShowerSetting.isShowDetail = this.autoPalySettingForm.isShowDetail;
      this.autoPlaySwitchInterval = this.autoPalySettingForm.switchInterval * this.autoPalySwitchIntervalUnit;

      // 3、重新开始页码
      this.pageNumber = 1;

      // 4、更新图片图片信息状态
      this.displayPictureList.forEach(p => {
        p.isShowDetail = this.autoPalySettingForm.isShowDetail;
      });

      if(this.displayPictureList && this.displayPictureList.length>0) {
        this.$confirm("播放配置已更新，是否立即立即播放?", "提示", {
        confirmButtonText: "立即播放",
        cancelButtonText: "稍后播放",
        type: "success"
        })
        .then(() => {
          this.playOrPauseAutoPlay(true);
        })
        .catch(() => {
          this.$message({
            type: "success",
            message: "更新播放配置成功，稍后手动处理图片播放"
          });
        });
      }
    },

    /**
     * @Author KR0288
     * @Date 2022/06/07 10:17:51
     * @Description 取消播放设置，清空表单数据
     */
    closePlaySetting(){
      this.autoPalySettingDialogVisible = false;
      this.$refs.autoPlayFormRef.resetFields();
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 09:24:44
     * @Description 查看目录窗口
     */
    handleCheckContent() {
      this.displayContentDrawerVisible = true;
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 09:53:07
     * @Description 判断图片是否正在被播放，true则添加黄色背景色
     */
    checkPictureIsActive(index) {
      if (this.currentDisplayIndex.length == 0) {
        return false;
      }
      return index >= this.currentDisplayIndex[0] && index < this.currentDisplayIndex[1];
    },

    /**
     * @Author KR0132
     * @Date 2022/02/22 11:26:12
     * @Description 处理点击目录下拉菜单
     */
    handleClickMenu(index, command) {
      console.log(index);
      switch (command) {
        case "moveToFront": {
          if (index == 0) {
            this.$message({ message: "当前图片已经是第一张，不能继续上移！", type: "warning" });
            break;
          }
          this.displayPictureList[index - 1].index = index;
          this.displayPictureList[index].index = index - 1;
          this.displayPictureList.sort((p1, p2) => {
            return p1.index - p2.index;
          });

          this.prePictureList[index - 1].index = index;
          this.prePictureList[index].index = index - 1;
          this.prePictureList.sort((p1, p2) => {
            return p1.index - p2.index;
          });
          break;
        }
        case "moveToNext": {
          if (index == this.displayPictureList.length - 1) {
            this.$message({ message: "当前图片已经是最后一张，不能继续下移！", type: "warning" });
            break;
          }
          this.displayPictureList[index + 1].index = index;
          this.displayPictureList[index].index = index + 1;
          this.displayPictureList.sort((p1, p2) => {
            return p1.index - p2.index;
          });

          this.prePictureList[index + 1].index = index;
          this.prePictureList[index].index = index + 1;
          this.prePictureList.sort((p1, p2) => {
            return p1.index - p2.index;
          });
          break;
        }
        case "remove": {
          this.$confirm("继续此项操作会从列表移除当前图片, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              this.displayPictureList.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除"
              });
            });

          break;
        }
        case "beginHere": {
          if (this.isPlaying) {
            this.playOrPauseAutoPlay(false);
          }
          this.beginIndex = index;
          this.pageNumber = 1;
          this.playOrPauseAutoPlay(true);
          break;
        }
      }
    },

    /**
     * @Author KR0132
     * @Date 2022/02/21 11:31:21
     * @Description 判断是否有界面元素是否全屏
     */
    checkFull() {
      var isFull = window.fullScreen || document.webkitIsFullScreen || document.msFullscreenEnabled || document.mozRequestFullScreen;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
  },

  watch: {
    /*
     * @Date 2022/04/02 15:33:02
     * 监听离开此界面事件，离开此界面需要关闭定时器
     */
    currentMenu: function() {
      if(this.preMenu == "/photo-display" && this.currentMenu != this.preMenu) {
        console.log('离开图片轮播界面！');
         if (this.switchTimer != -1) {
          clearInterval(this.switchTimer);
        }

        if (this.percentTimer != -1) {
          clearInterval(this.percentTimer);
        }

        this.isPlaying = false;
        this.imageShowTimeCounter = 0;
      }
      this.preMenu = this.currentMenu;
    }
  },

  mounted() {
    // this.displayPictureList = this.prePictureList.map((p, index) => {
    //   p.index = index;
    //   return p;
    // });
    this.init();

    /*
     * @Date 2022/02/21 11:46:16
     * 判断是否全屏需要使用window原生监听器
     */
    window.onresize = () => {
      this.isFullScreen = this.checkFull();
    };

    /*
     * @Date 2022/02/22 17:15:56
     * 查询线路
     */
    getLineNames().then(res => {
      if (res.code === "000000") {
        this.lineList = res.data;
      }
    });
  },

  beforeDestroy() {
    // 界面销毁前执行操作
    if (this.switchTimer != -1) {
      clearInterval(this.switchTimer);
    }

    if (this.percentTimer != -1) {
      clearInterval(this.percentTimer);
    }
  },
};
</script>
<style lang="scss" scoped>
.display-page {
  .picture-show {
    margin-top: 1.5vh;
    height: 88vh;
  }

  .content-menu {
    width: 188px;
    background: rgb(255, 255, 255);
    color: #000;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(102, 175, 233, 0.6) 0px 0px 8px;
    z-index: 99999;
    position: fixed;
    padding: 10px;
    box-sizing: content-box;
    height: 30px;
  }
  .content-menu-list {
    padding: unset;
    margin: unset;
  }
  .content-menu > li {
    cursor: pointer;
    list-style: none;
    border-bottom: 1px solid #efefef;
    padding: 7px 10px;
  }
  li:last-child {
    border: unset;
  }
  li:hover {
    background: #ccc;
    color: #fff;
  }
}

.switch-progress {
  .onPlay {
    margin-left: 10px;
  }
}

.picture-check-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #00a880;
  .picture-checkbox {
    height: 10%;
  }
  .picture-item {
    align-self: center;
    flex: 1;
    width: 100%;
    height: 90%;
  }
}
</style>
